<template>
  <v-app>
    <v-app-bar ref="appBar" app flat elevate-on-scroll shrink-on-scroll>

      <div class="text-bold app-bar-title" v-bind:style="{ fontSize: headerFontSize + 'px', marginTop: headerMarginTop + 'px' }">
        COEUS <span class="text-yellow">HOLDINGS</span>
      </div>

      <v-spacer></v-spacer>

      <v-btn v-bind:style="{ height: navigationFontSize + 'px', fontSize: navigationFontSize + 'px', marginTop: navigationMarginTop + 'px' }" text v-on:click="$vuetify.goTo('#landingContainer', goToOptions)">
        HOME
      </v-btn>

      <v-btn v-bind:style="{ height: navigationFontSize + 'px', fontSize: navigationFontSize + 'px', marginTop: navigationMarginTop + 'px' }" text v-on:click="$vuetify.goTo('#aboutContainer', goToOptions)">
        ABOUT
      </v-btn>

      <v-btn v-bind:style="{ height: navigationFontSize + 'px', fontSize: navigationFontSize + 'px', marginTop: navigationMarginTop + 'px' }" text v-on:click="$vuetify.goTo('#contactContainer', goToOptions)">
        CONTACT
      </v-btn>

    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>

    <v-fab-transition>
      <v-btn
        v-scroll="onScroll"
        elevation="24"
        color="#ffc000"
        v-show="showGoToTop"
        fab
        large
        dark
        bottom
        right
        fixed
        v-on:click="$vuetify.goTo('#landingContainer', goToOptions)"
      >
        <v-icon>mdi-chevron-up</v-icon>
      </v-btn>
    </v-fab-transition>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => {
    return {
      headerFontSize: 128,
      headerMarginTop: 128,
      navigationFontSize: 128,
      navigationMarginTop: 128,
      offsetTop:0,
      goToOptions: {
        duration: 500,
        offset: 0,
        easing: 'easeInOutCubic',
      },
    };
  },
  computed:{
    showGoToTop () {
      return this.offsetTop > 200;
    },
  },
  methods: {
    onScroll (event) {
      this.offsetTop = event.target.scrollingElement.scrollTop;

      // Header text resizing
      this.headerFontSize = this.$refs.appBar.styles.height.slice(0,-2) * 0.5;
      this.headerMarginTop = this.$refs.appBar.styles.height.slice(0,-2) * 0.08;

      // Navigation text resizing
      this.navigationFontSize = this.$refs.appBar.styles.height.slice(0,-2) * 0.3;
      this.navigationMarginTop = this.$refs.appBar.styles.height.slice(0,-2) * 0.3;
    },
  },
  mounted() {
    // Header text resizing
    this.headerFontSize = this.$refs.appBar.styles.height.slice(0,-2) * 0.5;
    this.headerMarginTop = this.$refs.appBar.styles.height.slice(0,-2) * 0.08;

    // Navigation text resizing
    this.navigationFontSize = this.$refs.appBar.styles.height.slice(0,-2) * 0.3;
    this.navigationMarginTop = this.$refs.appBar.styles.height.slice(0,-2) * 0.3;
  },
};
</script>

<style>

@font-face {
    font-family: 'AvenirPro';
    src: url("/AvenirLTPro-Roman.woff") format('woff');
}

@font-face {
    font-family: 'AvenirStd';
    src: url("/AvenirLTStd-Black.woff") format('woff');
}

body {
    font-family: 'AvenirPro';
}

.app-bar-title {
  white-space: nowrap;
}

.header-yellow {
 color: #ffc000;
 font-weight: bold;
}

.text-yellow {
 color: #ffc000;
}

.text-bold {
 font-weight: bold;
}
</style>