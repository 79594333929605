<template>
  <v-container id="contactContainer"  class="pa-0" fluid>
    <v-row style="height:50%;margin:0;">
      <v-col>
        <div style="position:relative;height:100%;">
          <div style="position:absolute;top:0;left:0;bottom:0;color:#232323;">
            <div class="header-yellow" style="width:50vw;margin-left:1vw;margin-top:3vw;margin-bottom:1vw;font-size:2.5vw;">CONTACT US</div>
            <div style="width:50vw;margin-left:1vw;margin-bottom:1vw;font-size:1.5vw;"><b>COEUS HOLDINGS - HEAD OFFICE</b><br>Office 1309, 13th Floor<br>Creative Tower<br>PO Box 4422<br>United Arab Emirates</div>
            <div style="width:50vw;margin-left:1vw;font-size:1.5vw;"><b>Email: contact@coeus.one</b></div>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row style="height:50%;margin:0;">
      <v-col id="contactImage">

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Contact",

  data: () => ({
    //
  }),
};
</script>

<style>
#contactContainer{
  height:100vh;
  background-color: #ffffff;
}
#contactImage{
  background: url('../assets/shutterstock_551334580_high.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
</style>