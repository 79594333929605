<template>
  <v-container id="landingContainer" class="pa-0" fluid>
    <div style="position:relative;height:100%;background-color:rgba(0,0,50,0.4)">
      <div style="position:absolute;bottom:20vh;right:0;">
        <div class="text-bold" style="width:60vw;margin-right:1vw;font-size:3vw;text-align:right;">Connecting and Converging <span class="text-yellow">Governments & Organisations with International Investment, Expertise & Innovation</span></div>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "Landing",

  data: () => ({
    //
  }),
};
</script>

<style>
#landingContainer{
  height:100vh;
  background-image: url('../assets/shutterstock_1729719739_low.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
</style>