<template>
  <v-container id="aboutContainer" class="pa-0" fluid>
    <div style="position:relative;height:100%;">
      <div style="position:absolute;top:0;left:0;bottom:0;background-color:rgba(0,0,50,0.2)">
        <div class="header-yellow" style="width:50vw;margin-left:1vw;margin-right:1vw;margin-top:3vw;margin-bottom:2vw;font-size:2.5vw;">ABOUT US</div>
        <div class="text-bold" style="width:50vw;margin-left:1vw;margin-bottom:2vw;font-size:2.4vw;"><span class="text-yellow">Our mission</span> is to accelerate and provide value addition for sustainable socio-economic development and game changing wealth creation projects</div>
        <div class="text-bold" style="width:50vw;margin-left:1vw;font-size:2.4vw;">We do so <span class="text-yellow">uniquely</span> through an established and trusted network, which extends to the highest levels to prioritise our clients’ interests foremost and deliver <span class="text-yellow">best & fast results</span></div>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "About",

  data: () => ({
    //
  }),
};
</script>

<style>
#aboutContainer{
  height:100vh;
  background: url('../assets/shutterstock_1242837505_med.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
</style>