<template>
  <v-container fluid fill-height class="pa-0">
    <Landing />
    <About />
    <Contact />
  </v-container>
</template>

<script>
import Landing from "../components/Landing";
import About from "../components/About";
import Contact from "../components/Contact";

export default {
  name: "Home",

  components: {
    Landing,
    About,
    Contact,
  },
};
</script>
